import { Component, Input, afterNextRender } from '@angular/core';

@Component({
  selector: 'ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  /**
   * Icon color
   *
   * @required
   */
  @Input()
  color: 'white' | 'black' | 'no-color' = 'no-color';

  /**
   * Icon name
   *
   * @required
   */
  @Input()
  icon: string;

  /**
   * Button size
   * By default 'medium'
   */
  @Input()
  size: 'xs' | 'small' | 'medium' | 'large' | 'dialog' = 'medium';

  /**
   * Icon width
   *
   * @required
   */
  @Input()
  width: string;

  /**
   * Icon height
   *
   * @required
   */
  @Input()
  height: string;

  /**
   * Determines whether the icon is hidden for screen readers
   *
   * @required
   * @default false
   */
  @Input()
  ariaHidden: boolean = false;

  /**
   * Icon label for screen readers
   *
   * @default false
   */
  @Input()
  ariaLabel?: string;

  /**
   * Whether is browser or prerendering server
   */
  isBrowser: boolean = false;

  constructor() {
    afterNextRender(() => (this.isBrowser = true));
  }

  public get classes(): string[] {
    return [
      'icon',
      `icon-${this.icon}`,
      this.size ? `icon--${this.size}` : '',
      `icon--${this.color}`,
    ];
  }
}
