import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DialogNotificationModule } from '@shared/dialogs/dialog-notification/dialog-notification.module';
import { NavigationService } from '@shared/services/navigation.service';

import { LeadService } from '../lead.service';
import { OfferCalculationService } from '../offer-calculation.service';
import { RedirectionService } from './redirection.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // Material
    MatDatepickerModule,
    MatNativeDateModule,
    // Dialogs
    DialogNotificationModule,
  ],
  providers: [
    LeadService,
    NavigationService,
    OfferCalculationService,
    RedirectionService,
  ],
})
export class RedirectionModule {}
