import { CommonModule } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { IconComponent } from './icon.component';
import iconsConfig from './icons.json';
import { IconDefinition } from './types/icons';

type IconModuleDeps = [MatIconRegistry, DomSanitizer];

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, MatIconModule],
  exports: [IconComponent],
})
export class IconModule {
  iconsConfig: {
    folder: string;
    icons: IconDefinition[];
  } = iconsConfig;

  constructor() {}

  /**
   * Register all icons defined in `icons.json`.
   */
  static registerIcons(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ): void {
    iconsConfig.icons.forEach((icon: IconDefinition) => {
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(
          `${iconsConfig.folder}/${icon.url}`,
        ),
      );
    });
  }

  static deps(): IconModuleDeps {
    const iconRegistry = inject(MatIconRegistry);
    const sanitizer = inject(DomSanitizer);

    return [iconRegistry, sanitizer];
  }
}
